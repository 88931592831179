import { CContainer, CSpinner } from "@coreui/react";
import React from "react";
import { Redirect, Route } from "react-router";
import { useProfile } from "../../context/profile.context";

const PublicRoute = ({ children, ...routeProps }) => {
  const { profile, isLoading } = useProfile();

  if (isLoading && !profile) {
    return (
      <CContainer
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <CSpinner color="primary" style={{ width: "4rem", height: "4rem" }} />
      </CContainer>
    );
  }

  if (profile && !isLoading) {
    return <Redirect to="/" />;
  }

  return <Route {...routeProps}>{children}</Route>;
};

export default PublicRoute;
