import React, { createContext, useContext, useEffect, useState } from "react";
import { apiGetWithAuth } from "../misc/config";

const profileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // const [token, setToken] = useState({
  //   token: null,
  //   logout: false,
  // });

  const setLocalStorage = (token, user) => {
    localStorage.setItem("token", token);
    setProfile(user);
  };

  const removeToken = () => {
    localStorage.setItem("token", null);
    setProfile(null);
    // setToken({ token: null, logout: true });
  };

  const can = (permission) => {
    return profile.admin.admin_role.permissions;
  };

  useEffect(() => {
    setIsLoading(true);
    apiGetWithAuth("getMe")
      .then((result) => {
        if (
          result.data.data.role === "Admin" ||
          result.data.data.role === "Super Admin"
        ) {
          setProfile(result.data.data);
          setIsLoading(false);
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        setProfile(null);
        setIsLoading(false);
      });
  }, []);

  return (
    // jika useContext(profileContext) hasilnya adlaah yg didalam value
    <profileContext.Provider
      value={{ isLoading, profile, setLocalStorage, removeToken, can }}
    >
      {children}
    </profileContext.Provider>
  );
};

export const useProfile = () => useContext(profileContext);
